import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Eye } from "react-feather";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
export function AppointmentList({
  appointments,
  eventClickListView,
  pagination,
  setPagination,
}) {
  const [data, setData] = useState([]);
  const [paginationButtons, setPaginationButtons] = useState({
    current: 1,
    previous_disabled: true,
    next_disabled: false,
  });

  const handlePaginationClick = (pageNumber) => {
    let pagination = {};
    let current_pagination = { ...paginationButtons };
    if (pageNumber === "previous") {
      if (paginationButtons.current >= 1) {
        pagination = {
          current: current_pagination.current - 1,
          previous_disabled: false,
        };
      } else {
        pagination = {
          previous_disabled: true,
        };
      }
    } else if (pageNumber === "next") {
      pagination = {
        current:
          current_pagination.current + 1 <
          Math.ceil(
            appointments?.pagination?.total_records /
              appointments?.pagination?.page_size
          )
            ? current_pagination.current + 1
            : Math.ceil(
                appointments?.pagination?.total_records /
                  appointments?.pagination?.page_size
              ),
        previous_disabled: false,
        next_disabled:
          current_pagination.current + 1 <
          Math.ceil(
            appointments?.pagination?.total_records /
              appointments?.pagination?.page_size
          )
            ? false
            : true,
      };
    } else {
      pagination = {
        current: pageNumber + 1,
        previous_disabled: pageNumber + 1 === 1 ? true : false,
        next_disabled:
          pageNumber + 1 ===
          Math.ceil(
            appointments?.pagination?.total_records /
              appointments?.pagination?.page_size
          )
            ? true
            : false,
      };
    }
    setPaginationButtons(pagination);

    setPagination({ ...pagination, page_number: pageNumber + 1 });
  };
  var pagination = (
    <div className="mt-3">
      <Pagination aria-label="...">
        <ul className="pagination pagination-primary">
          <PaginationItem disabled={paginationButtons.current === 1}>
            <PaginationLink onClick={() => handlePaginationClick("previous")}>
              previous
            </PaginationLink>
          </PaginationItem>
          {[
            ...Array(
              appointments
                ? Math.ceil(
                    appointments?.pagination?.total_records /
                      appointments?.pagination?.page_size
                  )
                : 0
            ),
          ].map((_, index) => (
            <PaginationItem
              key={index}
              active={paginationButtons.current === index + 1}
            >
              <PaginationLink onClick={() => handlePaginationClick(index)}>
                {index + 1}
                {paginationButtons.current === index + 1 && (
                  <span className="sr-only">(current)</span>
                )}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem disabled={paginationButtons.next_disabled}>
            <PaginationLink onClick={() => handlePaginationClick("next")}>
              next
            </PaginationLink>
          </PaginationItem>
        </ul>
      </Pagination>
    </div>
  );

  const columns = [
    {
      name: "Client Name",
      selector: "client_details.name",
      sortable: true,
    },
    // {
    //   name: "Time",
    //   selector: "appointment_time",
    //   sortable: true,
    //   width: "25%",

    //   format: (row) => {
    //     const date = moment(row.appointment_time).format(
    //       "Do MMM, YY [at] h:mm a"
    //     );
    //     return (
    //       <div data-toggle="tooltip" data-placement="top" title={date}>
    //         {date}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   name: "Type",
    //   selector: "appointment_type.title",
    //   sortable: true,
    // },
    // {
    //   name: "Branch Name",
    //   selector: "branchDetails.name",
    //   sortable: true,
    // },
    {
      name: "Services",
      selector: "services",
      sortable: true,

      format: (row, index) => {
        const services = row.services.map((item) => item.description);
        return services.join(", ");
      },
    },
    {
      name: "Actions",
      center: true,
      selector: "actions",
      format: (row) => (
        <Eye
          height={20}
          className="cursor-pointer"
          onClick={() => eventClickListView(row.id)}
        />
      ),
    },
  ];
  useEffect(() => {
    if (appointments) {
      setData(appointments?.records);
    }
  }, [appointments]);
  return (
    <>
      <Fragment>
        <div className="table-responsive">
          <DataTable
            data={data}
            columns={columns}
            striped={true}
            center={false}
            // selectableRows
            persistTableHead
          />
        </div>

        <div className="d-flex align-items-baseline justify-content-end">
          {pagination}
        </div>
      </Fragment>
    </>
  );
}
